import QrScanner from 'qr-scanner';
import {onDomReady} from "../../components/dynamic/observer";

class QR {
  constructor(videoElement, startElement, popupElement, cancelElement, debugElement) {
    this.videoElement = videoElement;
    this.startElement = startElement;
    this.popupElement = popupElement;
    this.cancelElement = cancelElement;
    this.debugElement = debugElement;
    this.qr = this.createQr();
    this.bind();
  }
  createQr() {
    return new QrScanner(
      this.videoElement,
      (result) => {
        if (this.debugElement) {
          this.debugElement.innerHTML = JSON.stringify(result);
        }
        this.result(result);
        this.stop();
      }
    )
  }
  bind() {
    this.startElement.addEventListener('click', this.start.bind(this));
    this.cancelElement.addEventListener('click', this.stop.bind(this));
  }
  start() {
    this.qr.start()
      .then(() => {
        this.openPopup()
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
      });
  }
  stop() {
    this.qr.stop();
    this.closePopup()
  }
  openPopup() {
    this.popupElement.classList.add('_opened');
  }
  closePopup() {
    this.popupElement.classList.remove('_opened');
  }
  result(result) {
    if (this.isURL(result)) {
      window.location.href = result;
    }
  }

  isURL(str) {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  }
}


onDomReady(() => {
  const qr = new QR(
    document.querySelector('[data-qr-scanner-video]'),
    document.querySelector('[data-qr-scanner-start]'),
    document.querySelector('[data-qr-scanner-popup]'),
    document.querySelector('[data-qr-scanner-cancel]'),
    document.querySelector('[data-qr-scanner-debug]'),
  );
})